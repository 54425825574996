// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

/*
Unlike stated in the LICENSE file, it is not necessary to include the
copyright notice and permission notice when you copy code from this file.

Originally copied from y-websocket.js.
Note that retaining the package.json dependency upon "y-websocket.js" is
easier than attempting to depend upon "y-protocols/*" below.
*/

/**
 * @module provider/emitter
 */

/* eslint-env browser */

import * as Y from 'yjs' // eslint-disable-line
import * as time from 'lib0/time.js'
import * as buffer from 'lib0/buffer.js'
import * as encoding from 'lib0/encoding.js'
import * as decoding from 'lib0/decoding.js'
import * as syncProtocol from 'y-protocols/sync.js'
import * as authProtocol from 'y-protocols/auth.js'
import * as awarenessProtocol from 'y-protocols/awareness.js'
import * as mutex from 'lib0/mutex.js'
import { Observable } from 'lib0/observable.js'

const messageSync = 0
const messageQueryAwareness = 3
const messageAwareness = 1
const messageAuth = 2

/**
 *                       encoder,          decoder,          provider,          emitSynced, messageType
 * @type {Array<function(encoding.Encoder, decoding.Decoder, EmitterProvider, boolean,    number):void>}
 */
const messageHandlers = []

messageHandlers[messageSync] = (encoder, decoder, provider, emitSynced) => {
  encoding.writeVarUint(encoder, messageSync)
  const syncMessageType = syncProtocol.readSyncMessage(decoder, encoder, provider.doc, provider)
  if (emitSynced && syncMessageType === syncProtocol.messageYjsSyncStep2 && !provider.synced) {
    provider.synced = true
  }
}

messageHandlers[messageQueryAwareness] = (encoder, decoder, provider) => {
  encoding.writeVarUint(encoder, messageAwareness)
  encoding.writeVarUint8Array(encoder, awarenessProtocol.encodeAwarenessUpdate(provider.awareness, Array.from(provider.awareness.getStates().keys())))
}

messageHandlers[messageAwareness] = (encoder, decoder, provider) => {
  awarenessProtocol.applyAwarenessUpdate(provider.awareness, decoding.readVarUint8Array(decoder), provider)
}

messageHandlers[messageAuth] = (encoder, decoder, provider) => {
  authProtocol.readAuthMessage(decoder, provider.doc, permissionDeniedHandler)
}

/**
 * @param {EmitterProvider} provider
 * @param {string} reason
 */
const permissionDeniedHandler = (provider, reason) => console.warn(`Permission denied to access ${provider.url}.\n${reason}`)

/**
 * @param {EmitterProvider} provider
 * @param {Uint8Array} buf
 * @param {boolean} emitSynced
 * @return {encoding.Encoder}
 */
const readMessage = (provider, buf, emitSynced) => {
  const decoder = decoding.createDecoder(buf)
  const encoder = encoding.createEncoder()
  const messageType = decoding.readVarUint(decoder)
  const messageHandler = provider.messageHandlers[messageType]
  if (/** @type {any} */ (messageHandler)) {
    messageHandler(encoder, decoder, provider, emitSynced, messageType)
  } else {
    console.error('Unable to compute message')
  }
  return encoder
}

/**
 * @param {EmitterProvider} provider
 */
const setupWS = (provider) => {
  provider.wsconnecting = true
  provider.wsconnected = false
  provider.synced = false

  provider.onmessage = (msg) => {
    const data = buffer.fromBase64(msg.asString())
    provider.wsLastMessageReceived = time.getUnixTime()
    const encoder = readMessage(provider, new Uint8Array(data), true)
    if (encoding.length(encoder) > 1) {
      const message = encoding.toUint8Array(encoder)
      provider.send(buffer.toBase64(message))
    }
  }

  provider.wsLastMessageReceived = time.getUnixTime()
  provider.wsconnecting = false
  provider.wsconnected = true
  provider.wsUnsuccessfulReconnects = 0
  provider.emit('status', [{
    status: 'connected',
  }])
  // always send sync step 1 when connected
  const encoder = encoding.createEncoder()
  encoding.writeVarUint(encoder, messageSync)
  syncProtocol.writeSyncStep1(encoder, provider.doc)
  const message = encoding.toUint8Array(encoder)
  provider.send(buffer.toBase64(message))
  // broadcast local awareness state
  if (provider.awareness.getLocalState() !== null) {
    const encoderAwarenessState = encoding.createEncoder()
    encoding.writeVarUint(encoderAwarenessState, messageAwareness)
    encoding.writeVarUint8Array(encoderAwarenessState, awarenessProtocol.encodeAwarenessUpdate(provider.awareness, [provider.doc.clientID]))
    const msg2 = encoding.toUint8Array(encoderAwarenessState)
    provider.send(buffer.toBase64(msg2))
  }

  provider.emit('status', [{
    status: 'connecting',
  }])
}

/**
 * @param {EmitterProvider} provider
 * @param {ArrayBuffer} buf
 */
const broadcastMessage = (provider, buf) => {
  if (provider.wsconnected) {
    // @ts-ignore We know that wsconnected = true
    provider.send(buffer.toBase64(buf))
  }
}

/**
 * EmitterProvider for Yjs. Creates an emitter connection to sync the shared document.
 *
 * @example
 *   import * as Y from 'yjs'
 *   import { EmitterProvider } from './y-emitter.js'
 *   const doc = new Y.Doc()
 *   const provider = new EmitterProvider(send, doc)
 *
 * @extends {Observable<string>}
 */
export class EmitterProvider extends Observable {
  /**
   * @param {Function} send
   * @param {Y.Doc} doc
   * @param {object} [opts]
   * @param {boolean} [opts.connect]
   * @param {awarenessProtocol.Awareness} [opts.awareness]
   * @param {Object<string,string>} [opts.params]
   */
  constructor(
    send,
    doc,
    {
      connect = true,
      awareness = new awarenessProtocol.Awareness(doc),
    } = {}) {
    super()

    this.doc = doc
    this.awareness = awareness
    this.wsconnected = false
    this.wsconnecting = false
    this.wsUnsuccessfulReconnects = 0
    this.messageHandlers = messageHandlers.slice()
    this.mux = mutex.createMutex()
    /**
     * @type {boolean}
     */
    this._synced = false
    this.send = send
    this.wsLastMessageReceived = 0
    /**
     * Whether to connect to other peers or not
     * @type {boolean}
     */
    this.shouldConnect = connect

    /**
     * Listens to Yjs updates and sends them to remote peers (ws and broadcastchannel)
     * @param {Uint8Array} update
     * @param {any} origin
     */
    this._updateHandler = (update, origin) => {
      if (origin !== this || origin === null) {
        const encoder = encoding.createEncoder()
        encoding.writeVarUint(encoder, messageSync)
        syncProtocol.writeUpdate(encoder, update)
        broadcastMessage(this, encoding.toUint8Array(encoder))
      }
    }
    this.doc.on('update', this._updateHandler)
    /**
     * @param {any} changed
     * @param {any} origin
     */
    this._awarenessUpdateHandler = ({ added, updated, removed }) => {
      const changedClients = added.concat(updated).concat(removed)
      const encoder = encoding.createEncoder()
      encoding.writeVarUint(encoder, messageAwareness)
      encoding.writeVarUint8Array(encoder, awarenessProtocol.encodeAwarenessUpdate(awareness, changedClients))
      broadcastMessage(this, encoding.toUint8Array(encoder))
    }
    window.addEventListener('beforeunload', () => {
      awarenessProtocol.removeAwarenessStates(this.awareness, [doc.clientID], 'window unload')
    })
    awareness.on('update', this._awarenessUpdateHandler)
    if (connect) {
      this.connect()
    }
  }

  /**
   * @type {boolean}
   */
  get synced() {
    return this._synced
  }

  set synced(state) {
    if (this._synced !== state) {
      this._synced = state
      this.emit('synced', [state])
      this.emit('sync', [state])
    }
  }

  destroy() {
    if (this._resyncInterval !== 0) {
      clearInterval(this._resyncInterval)
    }
    clearInterval(this._checkInterval)
    this.disconnect()
    this.awareness.off('update', this._awarenessUpdateHandler)
    this.doc.off('update', this._updateHandler)
    super.destroy()
  }

  disconnect() {
    this.shouldConnect = false
  }

  connect() {
    this.shouldConnect = true
    if (!this.wsconnected) {
      setupWS(this)
    }
  }
}
